import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageExchange = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImageExchange: file(relativePath: { eq: "mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 612) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.placeholderImageExchange.childImageSharp.fluid}
      style={{ width: "306px" }}
      alt="Exchanging coins on the go"
    />
  )
}

export default ImageExchange
