import React from "react"
// import { Link } from "gatsby"

import Wrap from "../../components/wrap"
import HelpSVG from "../../svg/help.inline.svg"

const Aside = () => {
  return (
    <Wrap paddingY="py-0" className="max-w-full mb-4">
      <aside className="md:flex md:-mx-2 items-stretch">
        <section className="md:w-1/2 md:px-2 mb-4 md:mb-0">
          <div className="h-full p-8 bg-theme-blue-light text-center">
            <div
              data-sal-duration="400"
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            >
              <h2 className="h2">
                <HelpSVG className="-ml-10 mr-1" /> Help
              </h2>
              <div className="inline-block m-auto text-left">
                <p className="text-lg text-center mb-4">
                  <span className="block">
                    If you have any questions, problems
                  </span>{" "}
                  <span className="block">
                    or otherwise, don't hesitate to ask the community.
                  </span>
                </p>
                <ul className="leading-loose text-lg text-center">
                  {/* <li>
                    <a href="/#" className="target-link">
                      Watch instruction videos
                    </a>
                  </li>
                  <li>
                    <a href="/#" className="target-link">
                      Browse instruction articles
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://discordapp.com/invite/n7fAKJE"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      className="target-link"
                    >
                      Ask on Discord
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="h-full md:w-1/2 md:px-2">
          <div className="p-8 text-center bg-gradient-orange-to-yellow">
            <div
              data-sal-duration="300"
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            >
              <h2 className="h2">Join the community</h2>
              <p className="text-lg mb-6">
                <span className="md:block">
                  <span className="block">Join CoinCollect on Discord to get the latest app updates,</span>
                  <span className="block">give some feedback & meet fellow users.</span>
                </span>
              </p>
              <a href="https://discordapp.com/invite/n7fAKJE" target="_blank" className="btn border-2 border-black text-black">
                Go
              </a>
            </div>
          </div>

          {/* <div className="px-8 py-10 text-center bg-theme-gray-light">
            <div
              data-sal-duration="300"
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            >
              <h2 className="h2 leading-none mb-4">
                <span className="block">Get & Gift Free</span>
                <span className="block">Cryptocurrency</span>
              </h2>
              <Link
                to="/"
                className="inline-block mt-4 py-3 px-12 no-underline font-semibold cursor-pointer text-theme-gray-333333 rounded-full font-bold text-theme-body no-underline shadow-lg bg-gradient-btn"
              >
                Claim Your Gift
              </Link>
            </div>
          </div> */}
        </section>
      </aside>
    </Wrap>
  )
}

export default Aside
