import React, { useState } from "react"
import PropTypes from "prop-types"

import ToggleSVG from "../svg/toggle.inline.svg"

const Accordion = ({ title, children }) => {
  const [active, setActive] = useState(false)

  function toggleAccordion() {
    setActive(active === true ? false : true)
  }

  return (
    <div className="accordion md:max-w-md">
      <div
        className="flex justify-between items-center cursor-pointer select-none"
        onClick={toggleAccordion}
      >
        <h2 className="py-4 text-lg text-theme-blue font-normal">{title}</h2>
        <ToggleSVG
          className={`transition-duration ${active ? "rotate-45" : "rotate-0"}`}
        />
      </div>
      <div className={`pb-4 ${active ? "" : "hidden"}`}>{children}</div>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Accordion
