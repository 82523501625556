import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/home/hero"
import Aside from "../components/home/aside"
import Benefit from "../components/home/benefit"
import Accordion from "../components/accordion"
import Download from "../components/home/download"
import Specifications from "../components/home/specifications"

import ImageLogoEasy from "../components/images/logo-easy"
import ImageLogoSecure from "../components/images/logo-secure"
import ImageLogoExchange from "../components/images/logo-exchange"
import ImageBuyEasy from "../components/images/easy"
import ImageMobileApp from "../components/images/exchange"
import Footer from "../components/footer"
import CCL from "../svg/coins/CCL.inline.svg"

const IndexPage = () => (
  <Layout>
    <SEO
      title="CoinCollect: The perfect wallet to get started"
      description="Be part of the crypto revolution. Get in with CoinCollect. CoinCollect makes it easy for you to buy, manage and exchange your coins securely wherever you are."
    />

    <Hero />
    <Aside />

    <Benefit
      id="features"
      title="Buying coins is easy"
      icon={<ImageLogoEasy />}
      intro="Use your fiat, wherever you live, to buy coins. Quick and easy is our experience for anyone buying coins. That's why we work with local partners worldwide."
      bgColorClass="bg-theme-blue-lighter"
      bgImage={<ImageBuyEasy />}
    >
      <Accordion title="Who are these local partners?">
        <p>
          CoinCollect works together with <a href="https://www.nocks.com/" target="_blank">Nocks</a>, so you can buy crypto with euros using iDeal. We'll add other payments method in the future.
        </p>
      </Accordion>
      <Accordion title="KYC/AML explained">
        <p>
          Using CoinCollect you can buy 50 euros of crypto per week. If you want to buy more per week, you'll need to identify yourself at Nocks because of the of the 'Know Your Customer' and 'Anti Money Laundering' laws in Europe.
        </p>
      </Accordion>
    </Benefit>

    <Benefit
      title="Store your coins secure"
      icon={<ImageLogoSecure />}
      intro="Manage your coins in a safe environment. You don't have to worry about losing your coins since you own your private keys."
      bgColorClass="bg-white"
    >
      <Accordion title="What is a private key?">
        <p>
          Cryptocurrencies are a revolution in finance. In the past it was impossible to hold digital value without a third party to trust. Nowadays, as long as you have your digital 'private key', you will be the sole owner of your money. Open the app with private key & get access to your funds.
        </p>
      </Accordion>
      <Accordion title="Keep your private key secure">
        <p>
          It is important to keep your private key secure. Using CoinCollect you can backup your private key in the form of 24 words. Write the words on a piece of paper or store it in your password manager. As long as you have your private key you have access to your funds.
        </p>
      </Accordion>
    </Benefit>

    <Benefit
      title="Exchange coins on the go"
      icon={<ImageLogoExchange />}
      intro="Access anywhere and anytime to trade your coins. We help you set
              alerts so you know when it's time to claim your profit."
      bgColorClass="bg-theme-gray-light"
      bgImage={<ImageMobileApp />}
    >
      <Accordion title="Exchange from within your wallet">
        <p>
          CoinCollect will make it super easy to exchange coins. Trade Bitcoin for Ether or the other way around. All coins in the wallet are supported. Make profit on your way.
        </p>
      </Accordion>
{/*      <Accordion title="Profit Alerts explained" hidden>
        <p>
          Curabitur ligula sapien, tincidunt non, euismod vitae, posuere
          imperdiet, leo. Maecenas malesuada. Praesent congue erat at massa. Sed
          cursus turpis vitae tortor. Donec posuere vulputate arcu. Phasellus
          accumsan cursus velit.
        </p>
      </Accordion>*/}
    </Benefit>

    <Download />
    <Specifications />

    <Footer />

    <Link
      to="/#download"
      className="fixed bottom-0 right-0 flex items-center mb-4 sm:mb-8 mr-4 sm:mr-8 rounded-full font-bold text-theme-body no-underline shadow-lg bg-gradient-btn"
    >
      <div className="pl-5 pr-3">Download for iOS & Android</div>
      <div className="flex bg-white w-12 h-12 rounded-full">
        <CCL className="w-8 h-8 m-auto" />
      </div>
    </Link>

  </Layout>
)

export default IndexPage
