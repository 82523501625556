import React from "react"

import Wrap from "../wrap"
import AppDownloadButtons from "../../components/download-buttons"

const Download = () => {
  return (
    <div className="bg-theme-blue-light mb-4" id="download">
      <Wrap className="max-w-xl">
        <section
          data-sal-duration="300"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h1 className="h1 text-center md:pt-4">
            <span className="md:block">The Future of Money is Easy</span>{" "}
            <span className="md:block">Download CoinCollect</span>
          </h1>
          <AppDownloadButtons />
          <a
            href="https://github.com/CoinCollect/coincollect"
            target="_blank"
            rel="noopener noreferrer"
            className="block target-link text-center"
            style={{display: 'none'}}
          >
            Source code on Github
          </a>
        </section>
      </Wrap>
    </div>
  )
}

export default Download
