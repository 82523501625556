import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageLogoSecure = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImageLogoSecure: file(
        relativePath: { eq: "logo-secure.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 94) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.placeholderImageLogoSecure.childImageSharp.fluid}
      style={{ width: "47px" }}
      alt="Icon Secure"
    />
  )
}

export default ImageLogoSecure
