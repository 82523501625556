import React from "react"
import { Link } from "gatsby"

import Wrap from "../wrap"
import ImageHero from "../images/hero"

import AppleWhiteSVG from "../../svg/apple-white.inline.svg"
import AndroidwhiteSVG from "../../svg/android-white.inline.svg"

const Hero = () => {
  return (
    <div className="mb-4 bg-gradient-light-blue-to-blue">
      <div className="container text-center pt-24">
        <header className="px-4 mb-8 sm:mb-10 text-white">
          <h1
            className="h1"
            data-sal-duration="400"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-bounce"
          >
            We Make Crypto Easy
          </h1>
          <p
            className="text-2xl leading-snug"
            data-sal-duration="400"
            data-sal="slide-up"
            data-sal-delay="600"
            data-sal-easing="ease-out-bounce"
          >
            Get CoinCollect for mobile.
            <br />
            The perfect wallet to get started.
          </p>
        </header>
        <div
          className="flex flex-no-wrap md:justify-center md:-ml-12"
          data-sal-duration="300"
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <div className="hidden md:flex items-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.coincollect.app"
              target="_blank"
              rel="noopener noreferrer noffolow"
            >
              <AndroidwhiteSVG className="mr-4" />
            </a>
            <a
              href="https://apps.apple.com/us/app/coincollect/id1473471322"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <AppleWhiteSVG className="mr-4" />
            </a>
          </div>
          <div className="flex flex-wrap md:flex-no-wrap px-4 md:px-0">
            <Link
              to="/#download"
              className="btn w-full md:w-auto mr-4 mb-4 md:mb-0 border-2 border-transparent bg-theme-yellow text-black"
            >
              Get started now
            </Link>
            <Link
              to="/#features"
              className="btn w-full md:w-auto mr-4 border-2 border-theme-yellow bg-theme-blue text-theme-yellow"
            >
              Discover features
            </Link>
          </div>
        </div>
      </div>
      <Wrap maxWidth="max-w-full" paddingY="0">
        <div className="flex justify-center pt-12">
          <ImageHero />
        </div>
      </Wrap>
    </div>
  )
}

export default Hero
