import React from "react"
import PropTypes from "prop-types"

import Wrap from "../../components/wrap"

const Benefit = ({
  id,
  title,
  intro,
  bgColorClass,
  children,
  icon,
  bgImage,
}) => {
  return (
    <div className={`${bgColorClass} mb-4`} id={id}>
      <Wrap maxWidth="max-w-xl">
        <article
          className="md:pl-12"
          data-sal-duration="300"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <header className="relative mb-10">
            <div className="hidden md:block absolute top-0 left-0 -ml-32 w-32">
              <div className="float-right pr-5">{icon}</div>
            </div>
            <h1 className="h1">{title}</h1>
            <p className="text-xl leading-tight md:pt-2">{intro}</p>
          </header>

          {children}
        </article>
      </Wrap>
      <div className="flex justify-center px-4 m-auto md:pt-4">{bgImage}</div>
    </div>
  )
}

Benefit.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  bgColor: PropTypes.string,
}

export default Benefit
