import React from "react"
import Wrap from "../wrap"
import { v4 } from "uuid"
import SPECIFICATIONS from "../../data/specifications"

const Specifications = () => {
  return (
    <div className="pt-10 md:pt-10 bg-theme-yellow-light" id="specifications">
      <Wrap className="max-w-full pb-0" paddingY="py-8">
        <article
          data-sal-duration="300"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <header className="pb-6 text-center">
            <h1 className="h1">Specifications</h1>
          </header>

          <div className="md:flex md:flex-wrap -mx-4 md:mx-0">
            {SPECIFICATIONS.map(item => (
              <div key={v4()} className="spec-item md:w-1/2 md:p-2">
                <div className="h-full px-4 md:px-16 py-6 md:py-8">
                  <h2 className="h3">{item.title}</h2>
                  <p className="text-lg">{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </article>
      </Wrap>
    </div>
  )
}

export default Specifications
