import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageEasy = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImageEasy: file(relativePath: { eq: "buy-easy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1248) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.placeholderImageEasy.childImageSharp.fluid}
      style={{ width: "624px" }}
      alt="Buying coins is easy"
    />
  )
}

export default ImageEasy
